import styled from "@emotion/styled"

const Container = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

export default Container
