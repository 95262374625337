import React from "react"
import styled from "@emotion/styled"

const Root = styled.div`
  width: calc(100% + 1.0875rem * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 50px 0;
  background-color: #f9f9f9;
`

const Container = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  .project {
    margin-bottom: 40px;
  }
  .divider {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Description = styled.div`
  width: 100%;
  /* width: calc(100% - 1.0875rem * 2); */
  h1 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 16px;
  }
  img {
    width: 100%;
    max-height: 377px;
    object-fit: cover;
  }
  ul {
    margin-bottom: 0;
    li {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 28px;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

// const CustomerAbstract = styled.div`
//   width: calc(100% - 1.0875rem * 2);
//   h1 {
//     font-size: 20px;
//     font-weight: bold;
//     letter-spacing: 0;
//     line-height: 30px;
//     margin-bottom: 16px;
//   }
//   h2 {
//     font-size: 16px;
//     letter-spacing: 0;
//     line-height: 32px;
//     margin-bottom: 16px;
//   }
//   img {
//     width: 100%;
//     max-height: 377px;
//     object-fit: cover;
//   }
//   ul {
//     margin-bottom: 0;
//     li {
//       font-size: 14px;
//       letter-spacing: 0;
//       line-height: 28px;
//     }
//   }
// `

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
`

const ProjectAbstract = props => {
  const projectAbstractHtml = props.projectAbstract?.childMarkdownRemark?.html
  const customerAbstractHtml = props.customerAbstract.childMarkdownRemark.html

  return (
    <Root>
      <Container>
        {projectAbstractHtml && (
          <>
            <Description
              className="project"
              dangerouslySetInnerHTML={{ __html: projectAbstractHtml }}
            />
            <Divider className="divider" />
          </>
        )}
        <Description
          className="customer"
          dangerouslySetInnerHTML={{ __html: customerAbstractHtml }}
        />
      </Container>
    </Root>
  )
}

export default ProjectAbstract