import React from "react"
import styled from "@emotion/styled"
import DynamicImage from "../dynamic-image"
import FlagIcon from "@material-ui/icons/Flag"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const Root = styled.div`
  padding: 40px 0 50px 0;
`

const Section = styled.div`
  margin-bottom: 40px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 0;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: #243B88;
  }
`

const ImageContainer = styled.div`
  margin-bottom: 40px;
`

const Description = styled.div`
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 12px;
  }
  li {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
`

const Introduction = props => {
  const introduction = props.introduction
  const image = props.image
  const issues = props.issues
  const solutions = props.solutions
  const issuesHTML = issues?.childMarkdownRemark.html
  const solutionsHTML = solutions?.childMarkdownRemark.html
  const introductionHTML = introduction?.childMarkdownRemark.html

  return (
    <Root>
      <ImageContainer>
        {image && <DynamicImage image={image} />}
      </ImageContainer>
      {issues && (
        <Section>
          <Title>
            <FlagIcon />
            <h1>お客様の課題</h1>
          </Title>
          <Description dangerouslySetInnerHTML={{ __html: issuesHTML }} />
        </Section>
      )}
      {solutions && (
        <Section>
          <Title>
            <CheckCircleIcon />
            <h1>解決策</h1>
          </Title>
          <Description dangerouslySetInnerHTML={{ __html: solutionsHTML }} />
        </Section>
      )}
      {introduction && (
        <Description dangerouslySetInnerHTML={{ __html: introductionHTML }} />
      )}
    </Root>
  )
}

export default Introduction
