import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const OfficerProfileImage = props => {
  const image = props.image
  const src = getImage(image)
  let title = image?.title
  if (!title) {
    title = "株式会社アクア"
  }

  return (
    <GatsbyImage
      image={src}
      alt={title}
      style={{ borderRadius: "100%", height: "100%" }}
      imgStyle={{ borderRadius: "100%" }}
    />
  )
}

export default OfficerProfileImage
