import React from "react"
import styled from "@emotion/styled"
import Container from "./container"

const Root = styled.div`
  width: calc(100% + 1.0875rem * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
`

const Description = styled.div`
  /* width: calc(100% - 1.0875rem * 2); */
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
  img {
    width: 100%;
    max-height: 377px;
    object-fit: cover;
    margin-top: 20px;
  }
  iframe {
    margin-top: 40px;
    max-width: 100%;
  }
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

const FeaturesAbstract = ({ data }) => {
  const html = data.childMarkdownRemark.html

  return (
    <Root>
      <Container>
        <Description dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Root>
  )
}

export default FeaturesAbstract
