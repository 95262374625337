import React from "react"
import styled from "@emotion/styled"
import Container from "./container"
import TryIcon from "../../images/svg/reviews_black_24dp.svg"

const Root = styled.div`
  width: calc(100% + 1.0875rem * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
  background-color: #e9f7ff;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: start;
  width: 100%;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 0;
    /* width: 100%; */
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: #243B88;
  }
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

const Description = styled.div`
  /* width: calc(100% - 1.0875rem * 2); */
  /* h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  } */
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
  p {
    img {
      // 調整
      max-height: 377px;
      margin-bottom: 10px;
    }
  }
  ul {
    margin: 0 0 20px 0;
    list-style: none;
    li {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      margin-bottom: 0;
    }
  }
  img {
    width: 100%;
    max-height: 377px;
    object-fit: cover;
  }
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

const CustomersFeedback = ({ data }) => {
  const html = data.childMarkdownRemark.html

  return (
    <Root>
      <Container>
        <Title>
          {/* <FeedbackIcon /> */}
          <TryIcon />
          <h1>お客様の声</h1>
        </Title>
        <Description dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Root>
  )
}

export default CustomersFeedback
