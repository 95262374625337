import React from "react"
import styled from "@emotion/styled"
import Container from "./container"
import OfficerProfileImage from "../officer-profile-image"
import ChatIcon from '@material-ui/icons/Chat';

const Root = styled.div`
  width: calc(100% + 1.0875rem * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0 50px 0;
  background-color: #f9f9f9;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: start;
  width: 100%;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 0;
    /* width: 100%; */
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: #243B88;
  }
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

const Description = styled.div`
  /* width: calc(100% - 1.0875rem * 2); */
  width: 100%;
  margin-bottom: 20px;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
  p {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
  img {
    width: 100%;
    max-height: 377px;
    object-fit: cover;
  }
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

const DeveloperProfile = styled.div`
  /* width: calc(100% - 1.0875rem * 2); */
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 799px) {
    width: calc(100% - 1.0875rem * 2);
  }
`

const DeveloperPhoto = styled.div`
  width: 80px;
  height: 80px;
  /* margin-right: 17px; */
`

const DeveloperDescription = styled.div`
  width: calc(100% - 80px);
  max-height: 64px;
  padding-left: 17px;
  p {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
`

const DevelopersFeedback = ({ data }) => {
  const html = data.description.childMarkdownRemark.html
  const developerName = data.developerName
  const developerPhoto = data.developerPhoto
  const developerPosition = data.developerPosition

  return (
    <Root>
      <Container>
        <Title>
          <ChatIcon />
          <h1>開発者の声</h1>
        </Title>
        <Description dangerouslySetInnerHTML={{ __html: html }} />
        <DeveloperProfile>
          <DeveloperPhoto>
            {/* <DynamicImage image={developerPhoto.gatsbyImageData} /> */}
            <OfficerProfileImage image={developerPhoto.gatsbyImageData} />
          </DeveloperPhoto>
          <DeveloperDescription>
            <p>{developerPosition}</p>
            <p>{developerName}</p>
          </DeveloperDescription>
        </DeveloperProfile>
      </Container>
    </Root>
  )
}

export default DevelopersFeedback
