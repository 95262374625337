import React from "react"
import Seo from "../components/seo.js"
import styled from "@emotion/styled"
import Container from "../components/development-case/container.js"
import ProductAbstract from "../components/development-case/product-abstract"
import Introduction from "../components/development-case/introduction.js"
import CustomersFeedback from "../components/development-case/customers-feedback.js"
import DevelopersFeedback from "../components/development-case/developers-feedback.js"
import FeaturesAbstract from "../components/development-case/features-abstract.js"
import ProjectAbstract from "../components/development-case/project-abstract.js"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"

const Root = styled.div`
  padding-top: 40px;
  margin-bottom: -1.45rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  a {
    text-decoration: underline;
  }
  .video {
    width: 100%;
    aspect-ratio: 16/9;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`

const Title = styled.h1`
  color: #222222;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 45px;
  margin-bottom: 0;
  padding-bottom: 40px;
  width: 100%;
`

const DevelopmentCase = ({ pageContext }) => {
  const { setIsAtContactPage } = UsePageContext()
  useState(
    () => {
      setIsAtContactPage(false)
    }
  )
  const data = pageContext.data
  const title = data.title
  const productAbstract = data.productAbstract
  const productName = productAbstract.productName
  const description = data.description
  const tags = data.metadata.tags
  const image = data.image
  const issues = data.issues
  const solutions = data.solutions
  const introduction = data.introduction
  const customersFeedback = data.customersFeedback
  const developerFeedback = data.developerFeedback
  const featuresAbstract = data.featuresAbstract
  const projectAbstract = data.projectAbstract
  const customerAbstract = data.customerAbstract

  return (
    <>
      <Seo title={`${productName} | 開発事例`} description={description} />
      <Root>
        <Container>
          <Title>{title}</Title>
        </Container>
        <ProductAbstract productAbstract={productAbstract} tags={tags} />
        <Container>
          <Introduction image={image} issues={issues} solutions={solutions} introduction={introduction} />
        </Container>
        <>
          {customersFeedback && <CustomersFeedback data={customersFeedback} />}
        </>
        <>
          {developerFeedback && <DevelopersFeedback data={developerFeedback} />}
        </>
        <>
          {featuresAbstract && <FeaturesAbstract data={featuresAbstract} />}
        </>
        <ProjectAbstract
          projectAbstract={projectAbstract}
          customerAbstract={customerAbstract}
        />
      </Root>
    </>
  )
}

export default DevelopmentCase
