import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import DynamicImage from "../dynamic-image"

const Root = styled.div`
  padding: 40px 0 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  // 画面いっぱいに表示
  width: calc(100% + 1.0875rem * 2);
  @media screen and (max-width: 799px) {
    flex-direction: column;
  }
`

const Container = styled.div`
  width: calc(100% - 1.0875rem * 2);
  max-width: 680px;
  display: flex;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 699px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
`

const Image = styled.div`
  width: calc(100% - 60px - 306px);
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 699px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`

const Text = styled.div`
  max-width: 306px;
  display: flex;
  flex-direction: column;
`

const Descriptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media screen and (max-width: 799px) {
    max-width: auto;
    width: 100%;
  }
`

const CompanyName = styled.h1`
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
`

const ProductName = styled.h2`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 0;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  .tag {
    margin-right: 4px;
  }
  .tag:last-child {
    margin-right: 0;
  }
`

const Tag = styled.span`
  padding: 6px 12px 6px 12px;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  background-color: #efefef;
  /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15); */
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 12px;
  text-align: center;
`

const ProductAbstract = props => {
  const productAbstract = props.productAbstract
  const image = productAbstract.productImage
  const companyName = productAbstract.companyName
  const productName = productAbstract.productName
  const tags = props.tags

  return (
    <Root>
      <Container>
        <Image>
          <DynamicImage image={image} />
        </Image>
        <Text>
          <Descriptions>
            <CompanyName className="company">{companyName}</CompanyName>
            <ProductName>{productName}</ProductName>
          </Descriptions>
          <Tags>
            {tags.map(tag => {
              const key = tag.contentful_id
              const name = tag.name
              return <Tag className="tag" key={key}>{name}</Tag>
            })}
          </Tags>
        </Text>
      </Container>
    </Root>
  )
}

ProductAbstract.propTypes = {
  productAbstract: PropTypes.shape({
    title: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    productImage: PropTypes.any.isRequired,
    productName: PropTypes.string.isRequired,
  }),
  tags: PropTypes.array.isRequired,
}

export default ProductAbstract
